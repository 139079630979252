import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Box, Flex, Theme, useColorMode, useTheme } from "@chakra-ui/react"
import { ClassNames, css } from "@emotion/react"
import { textVide } from "text-vide"
import { SettingsContext } from "./context/Settings"

const Bio = styled.a`
  color: ${ props => props.theme.colors.gray[300] };
  b { font-weight: 600; }
  &.active {
    color: ${ props => props.theme.colors.current };
  }
  &:focus {
    border-bottom: 2px solid ${ props => props.theme.colors.green[300]};
  }
`

const SizeWrapper = styled.a`
  padding: 5px;
  &:focus { outline: none !important; };
  &:focus svg {
    fill: ${ props => props.theme.colors.green[300]};
  }
`

const ModeWrapper = styled.a`
  padding: 1px;
  display: inline-block;
  vertical-align: middle;
  &:focus { outline: none !important; };
  &:focus svg {
    fill: ${ props => props.theme.colors.green[300]};
  }
`

const Size = ( {size, active, onClick}: {size: number, active: boolean, onClick: () => void } ) => {
  const theme = useTheme<Theme>()
  return (
    <SizeWrapper href="#" onClick={(e) => {onClick(); e.preventDefault();}}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={active ? theme.colors.current : theme.colors.gray[300]} width={size} height={size} viewBox="0 0 24 24"><path d="M24 20v1h-4v-1h.835c.258 0 .405-.178.321-.422l-.473-1.371h-2.231l-.575-1.59h2.295l-1.362-4.077-1.154 3.451-.879-2.498.921-2.493h2.222l3.033 8.516c.111.315.244.484.578.484h.469zm-6-1h1v2h-7v-2h.532c.459 0 .782-.453.633-.887l-.816-2.113h-6.232l-.815 2.113c-.149.434.174.887.633.887h1.065v2h-7v-2h.43c.593 0 1.123-.375 1.32-.935l5.507-15.065h3.952l5.507 15.065c.197.56.69.935 1.284.935zm-10.886-6h4.238l-2.259-6.199-1.979 6.199z"/></svg>
    </SizeWrapper>
  )
}

const Mode = () => {
  const theme = useTheme<Theme>()
  const { toggleColorMode } = useColorMode()
  return (
    <ModeWrapper href="#" onClick={(e) => { toggleColorMode(); e.preventDefault(); }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={theme.colors.current} style={{ width: "1rem", height: "1rem" }} width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 12c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm-3-5c.343 0 .677.035 1 .101v-3.101h-2v3.101c.323-.066.657-.101 1-.101zm-2.755.832l-2.195-2.196-1.414 1.414 2.195 2.195c.372-.561.853-1.042 1.414-1.413zm-2.245 4.168c0-.343.035-.677.101-1h-3.101v2h3.101c-.066-.323-.101-.657-.101-1zm9.169-2.754l2.195-2.195-1.414-1.415-2.195 2.195c.561.372 1.042.853 1.414 1.415zm.73 1.754c.066.323.101.657.101 1s-.035.677-.101 1h3.101v-2h-3.101zm-2.144 5.168l2.195 2.195 1.414-1.414-2.195-2.195c-.372.562-.853 1.043-1.414 1.414zm-6.924-1.414l-2.195 2.196 1.414 1.414 2.195-2.195c-.561-.372-1.042-.853-1.414-1.415zm4.169 2.246c-.343 0-.677-.035-1-.101v3.101h2v-3.101c-.323.066-.657.101-1 .101z"/></svg>
    </ModeWrapper>
  )
}

function TextSize( props: { size: "sm" | "md" | "lg" }){
  const { setFontSize, fontSize } = useContext(SettingsContext)
  const active = fontSize === props.size

  return (
    <Size active={active} onClick={() => {
      setFontSize(props.size)
    }} size={{
      "sm": 16,
      "md": 24,
      "lg": 36
    }[props.size]} />
  )
}

function Bionic(){
  const { setBionic, bionic } = useContext(SettingsContext)
  return (
    <Bio href="#" className={bionic ? "active" : ""} onClick={(e) => { setBionic(!bionic); e.preventDefault(); }} dangerouslySetInnerHTML={{ __html: textVide("Bionic") }} />
  )
}

function ThemeSetting(){
  return (
    <Mode />
  )
}

const settings = css`
  border-bottom: 2px solid transparent;
`

function Setting( {children, border }: {children: React.ReactNode, border?: boolean } ){
  return (
    <Box px={4} borderLeft={ border ? "1px solid #ccc" : "" }>
      { children }
    </Box>
  )
}

export function Settings(){
  return (
    <ClassNames>
      { ({css}) => <Flex className={css(settings)} p={1} justifyContent="center" alignItems={"center"}>
        <Setting>
          <Flex alignItems={"center"}>
            <TextSize size="sm" />
            <TextSize size="md" />
            <TextSize size="lg" />
          </Flex>
        </Setting>
        <Setting border={true}>
          <Bionic />
        </Setting>
        <Setting border={true} >
          <ThemeSetting />
        </Setting>
      </Flex> }
    </ClassNames>
  )
}
