import * as React from "react"
import { textVide } from "text-vide"
import { SettingsContext } from "./context/Settings"

export function Bionic({ children}: {children: string}) {
  const { bionic } = React.useContext(SettingsContext)
  return bionic ?
    <span style={{ fontWeight: 200 }} dangerouslySetInnerHTML={{ __html: textVide(children) }} />
    : <>{ children }</>
}
