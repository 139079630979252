import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import axios from "axios"
import React, { useState } from "react"
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { Bionic } from "./Bionic"

const StyledLink = styled.a`
  color: ${ p => p.theme.colors.current };
  margin: 0 0.25rem;
  border-bottom: 2px solid transparent;
  &:focus, &:hover {
    color: ${ p => p.theme.colors.green[300] };

    &:not(.icon){
      border-bottom: 2px solid ${ p => p.theme.colors.green[300] };
    }
  }

  margin-top:.5rem;
  @media(min-width: ${ p => p.theme.breakpoints.md }){
    margin-top:0;
  }
`

const GreenLink = styled.a`
  color: ${ p => p.theme.colors.green[300]};
  border-bottom: 2px solid ${ p => p.theme.colors.green[300]}
`

const LinkedIn = () => {
  const theme = useTheme()
  return (
    <StyledLink className="icon" href={"https://www.linkedin.com/in/lukesiedle/"} target="_blank" rel="nofollow noopener">
      <svg xmlns="http://www.w3.org/2000/svg" fill={"currentcolor"} style={{ width: '1.5rem', height: '1.5rem' }} width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 8c0 .557-.447 1.008-1 1.008s-1-.45-1-1.008c0-.557.447-1.008 1-1.008s1 .452 1 1.008zm0 2h-2v6h2v-6zm3 0h-2v6h2v-2.861c0-1.722 2.002-1.881 2.002 0v2.861h1.998v-3.359c0-3.284-3.128-3.164-4-1.548v-1.093z"/></svg>
    </StyledLink>
  )
}

const GitHub = () => {
  const theme = useTheme()
  return (
    <StyledLink className="icon" href={"https://github.com/lukesiedle/"} target="_blank" rel="nofollow noopener">
      <svg xmlns="http://www.w3.org/2000/svg" fill={"currentcolor"} style={{ width: '1.5rem', height: '1.5rem' }} width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg>
    </StyledLink>
  )
}

function Download(){
  const { isOpen, onOpen, onClose } = useDisclosure()
  const hood = useDisclosure()
  const [verified, setVerified] = useState(false)
  const [email, setEmail] = useState("")
  const [downloadUrl, setDownloadUrl] = useState("")
  return (
    <>
      <StyledLink href="#cv" onClick={(e) => {onOpen(); e.preventDefault() }}>
        <Bionic>Request CV</Bionic>
      </StyledLink>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter='blur(6px)' />
        <ModalContent p={2} style={{ margin: "1rem"}}>
          <ModalHeader >
            { !verified ? <>Just checking you are human...</> : <>Download Luke Siedle's CV</> }
          </ModalHeader>
          <ModalBody>
            { email && <>Or get in touch at <GreenLink href={`mailto:${email}`}>{email}</GreenLink>.</>}
          </ModalBody>
          <GoogleReCaptchaProvider reCaptchaKey="6LddvXAgAAAAAFjQRo7aZroHSm-tAWj1ZVLDMNKm">
            <Button as="a" href={downloadUrl} disabled={!verified} type="button" colorScheme={"green"} mt={2}>Download</Button>
            { !verified &&
              <GoogleReCaptcha onVerify={(token) => {
                axios
                  .post("https://recaptcha.lukesiedle.workers.dev", {}, {
                  headers: {
                    'g-recaptcha': token,
                    'Content-type': 'application/json'
                  }
                }).then(( res ) => {
                  setVerified(true)
                  setDownloadUrl(res.data.cv_url)
                  setEmail(res.data.email)
                }).catch((e) => {
                  console.error(`Recaptcha failed: ${e.toString()}`)
                })
              }} />
            }
          </GoogleReCaptchaProvider>
        </ModalContent>
      </Modal>
    </>
  )
}

function Hood(){
  const {isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <StyledLink href="#underthehood" onClick={(e) => { onOpen(); e.preventDefault(); }}>
        <Bionic>What's under the hood?</Bionic>
      </StyledLink>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={2} style={{ margin: "1rem"}}>
          <ModalHeader >
            What's under the hood?
          </ModalHeader>
          <ModalBody>
            <Box my={2} as="p"><Bionic>NPM packages:</Bionic></Box>
            <Box my={2} as="ul">
              <li>Gatbsy</li>
              <li>@chakra-ui/react</li>
              <li>text-vide</li>
              <li>axios</li>
            </Box>

            <Box as="p">
              <GreenLink href="https://bionic-reading.com/" rel="nofollow noopener" target="_blank">
                <Bionic>Bionic reading</Bionic>
              </GreenLink>
              {" "}
                <Bionic>helps the user read words faster with less effort. Here it's provided by the</Bionic>
              {" "}
                <GreenLink href="https://github.com/Gumball12/text-vide" target="_blank" rel="nofollow noopener">text-vide</GreenLink>
              {" "}
              <Bionic>package. Google Recatcha is validated using a simple Cloudflare worker. Form responses are stored in Google Forms proxied through a Cloudflare worker.</Bionic>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export function Links(){
  return (
    <Flex mt={10} alignItems={"center"} justifyContent="center" flexDirection={{ base: "column", md: "row"}}>
      <LinkedIn />
      <GitHub />
      <Box px={2} opacity={0.25}>|</Box>
      <Download />
      <Box px={2} opacity={0.25}>|</Box>
      <Hood />
    </Flex>
  )
}
