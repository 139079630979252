import React from "react";
import styled from "@emotion/styled"
import { ColorMode, Theme, useColorMode, useColorModeValue, useTheme } from "@chakra-ui/react";

const StyledInfo = styled.div`
  padding: .5rem .25rem;
  max-width: 20rem;
  font-size: .75rem;
`

export function Info( { children }: { children: React.ReactNode }){
  const theme = useTheme<Theme>()
  const colorTip = useColorModeValue(theme.colors.black, theme.colors.white)
  return <StyledInfo style={{
    color: colorTip,
    maxWidth: '20rem'
  }}>{ children }</StyledInfo>
}
