import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";

type FontSize = "sm" | "md" | "lg"

interface SettingsContextInterface {
  fontSize: FontSize,
  fontSizePx: number,
  bionic: boolean,
  setFontSize: Dispatch<SetStateAction<FontSize>>,
  setBionic: Dispatch<SetStateAction<boolean>>
}

export const SettingsContext = createContext({
  fontSize: "sm",
  fontSizePx: 18,
  bionic: true,
  setFontSize: () => {},
  setBionic: () => {}
} as SettingsContextInterface)

export function SettingsProvider( {children}: {children: React.ReactNode}){

  const [fontSize, setFontSize] = useState<FontSize>("sm");
  const [fontSizePx, setFontSizePx] = useState<number>(18);
  const [bionic, setBionic] = useState<boolean>(true);
  useEffect(() => {
    setFontSizePx({
      "sm": 18,
      "md": 24,
      "lg": 32
    }[fontSize])
  }, [fontSize])

  return (
    <SettingsContext.Provider value={{
      fontSize,
      fontSizePx,
      bionic,
      setBionic,
      setFontSize
    }}>
      { children }
    </SettingsContext.Provider>
  )
}
