import React from "react";
import { Box, BoxProps, ChakraProvider, DarkMode } from "@chakra-ui/react"

export function Section( { children, title, ...boxProps }: BoxProps & { children: React.ReactNode, title?: string }){
  return (
    <Box pt={4} {...boxProps}>
      { title &&
        <>
          <h2>{title}</h2>
          <Box as="hr" my={2} />
        </>
      }
      <Box as="div">{ children }</Box>
    </Box>
  )
}
