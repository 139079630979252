import * as React from "react"
import { Button, Input, Modal, ModalBody, ModalContent, ModalOverlay, Box, useDisclosure, ModalHeader, Textarea, FormLabel, Spinner, Flex, Alert } from "@chakra-ui/react"
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import axios from "axios"
import { useForm } from 'react-hook-form';
import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";

const EmailLink = styled.a`
  color: ${ p => p.theme.colors.green[300] };
  border-bottom: 2px solid ${ p => p.theme.colors.green[300] };
  position: relative;
  &:focus::after {
    content: "";
    position: absolute;
    top: calc(100% + 2px);
    width: 100%;
    border-bottom: 2px solid ${ props => props.theme.colors.yellow[100]};
    left: 0;
  }
`

export function HireMe(){
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ verified, setVerified ] = React.useState(false);
  const [ formUrl, setFormUrl ] = React.useState("")
  const [ email, setEmail ] = React.useState("")

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitSuccessful }
  } = useForm();

  const onSubmit = (data: any) => {
    axios.post("https://form.lukesiedle.workers.dev", {
      ...data,
      url: formUrl
    }, {
      headers: {
        "content-type": "application/json"
      }
    }).catch((e) => {
      console.error(e)
    }).then((r) => {
      setTimeout(() => reset(), 1000 )
    })
  }

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  console.log(site)

  return (
    <>
      <span onClick={onOpen}><strong>Hire Me</strong></span>

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent style={{ margin: "1rem"}}>
          <ModalHeader>
            <Box as="h2" color="green.300" fontSize={"2xl"}>Get in touch</Box>
          </ModalHeader>
          <ModalBody pt={0} py={8}>

            <form onSubmit={handleSubmit(onSubmit)}>

              <Box mb={4}>
                <FormLabel>Your Name</FormLabel>
                <Input placeholder="Full Name" type="text" {...register("name")} required />
              </Box>

              <Box mb={4}>
                <FormLabel>Your Email</FormLabel>
                <Input placeholder="Email Address" type="email" {...register("email")} required />
              </Box>

              <Box mb={4}>
                <FormLabel>Your Requirements</FormLabel>
                <Textarea rows={5} placeholder="Project requirements" {...register("description")} required />
              </Box>

              <GoogleReCaptchaProvider reCaptchaKey="6LddvXAgAAAAAFjQRo7aZroHSm-tAWj1ZVLDMNKm">
                <Flex justifyContent={{ base: "center", md: "space-around"}} alignItems="center" flexDirection={{ base: "column", md: "row"}}>
                  <Button as="button" type="submit" colorScheme={"green"} mt={2} disabled={!verified || isSubmitting}>Get in Touch</Button>
                  <Box mt={2}>
                    { email && <>Or &nbsp;<EmailLink href={`mailto:${ email }`}>{email}</EmailLink></> }
                    { !email && "Verifying you are human..."}
                  </Box>
                </Flex>
                { !verified &&
                  <GoogleReCaptcha onVerify={( token ) => {
                    axios.post("https://recaptcha.lukesiedle.workers.dev", {}, {
                      headers: {
                        'g-recaptcha': token,
                        'Content-type': 'application/json'
                      }
                    }).then(( res ) => {
                      setFormUrl(res.data.form_url)
                      setEmail(res.data.email)
                      setVerified(true)
                    }).catch((e) => {
                      console.error(`Recaptcha failed: ${e.toString()}`)
                    })
                  }} />
                }
              </GoogleReCaptchaProvider>

              <AnimatePresence>
                {isSubmitSuccessful &&
                  (
                    <motion.div initial={{ opacity: 1 }} exit={{
                      opacity: 0,
                      height: 0
                    }}>
                      <Box pt={4}>
                        <Alert colorScheme={"green"}>Success! Thank you.</Alert>
                      </Box>
                    </motion.div>
                  )
                }
              </AnimatePresence>

            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
