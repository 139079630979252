import { extendTheme, Theme, Input, Textarea, Button } from "@chakra-ui/react"

// Input.defaultProps = { ...Input.defaultProps, ...{
//   focusBorderColor: "green.300"
// }}

// Textarea.defaultProps = { ...Textarea.defaultProps, ...{
//   focusBorderColor: "green.300"
// }}

// Button.defaultProps = { ...Button.defaultProps, ...{
//   focusBorderColor: "green.300"
// }}

export const chakraTheme = extendTheme({
  shadows: {},
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: 'green.300',
      }
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: 'green.300'
      }
    },
    Button: {
      baseStyle: {
        _focus: { boxShadow: '0 0 0 3px var(--chakra-colors-green-300)' }
      }
    }
  }
});
