import styled from "@emotion/styled"
import React, { useState, useEffect } from "react"
import { renderToString } from "react-dom/server"
import { Global, css, ClassNames, jsx } from "@emotion/react"
import { Theme, ChakraProvider, ColorMode, useColorMode, useColorModePreference, useTheme } from "@chakra-ui/react"
import { Tooltip } from "../components/Tooltip"

const StyledHighlight = styled.a`
  color: ${ props => props.theme.colors.green[300]};
  border-bottom: 2px solid ${ props => props.theme.colors.green[300]};
  display: inline-block;
  cursor: pointer;
  outline: none;
  position: relative;
  &:focus::after {
    content: "";
    position: absolute;
    top: calc(100% + 2px);
    width: 100%;
    border-bottom: 2px solid ${ props => props.theme.colors.yellow[100]};
    left: 0;
  }
`

const renderElementToString = ( theme: Theme, mode: ColorMode, Content: React.FC<any> ) => {
  return renderToString(<Content mode={mode} theme={theme} />)
}

export function Highlight( {children, onClick, Tip}: {children: React.ReactNode, onClick: () => void, Tip: React.FC<any>}){
  const mode = useColorMode()

  return (
    <Tooltip Tip={Tip}>
      <StyledHighlight key={mode.colorMode} href="#" onClick={( e ) => { onClick(); e.preventDefault(); }}>
        { children }
      </StyledHighlight>
    </Tooltip>
  )
}

