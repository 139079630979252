import * as React from "react"
import { ColorMode, Theme } from "@chakra-ui/react"
import { Info } from "../../components/Info"

export function Bg( props: {bottomSheet?: boolean, theme: Theme, mode: ColorMode} ){
  return (
    <Info {...props}>
      For over ten years I have worked in agencies and led WordPress and WooCommerce projects, among other technology stacks including JAMStack. I am now consulting full time.
    </Info>
  )
}

export function Redux( props: {bottomSheet?: boolean, theme: Theme, mode: ColorMode}){
  return (
    <Info {...props}>
      Redux &amp; Redux Toolkit provides a powerful state management solution, especially when paired with Redux Saga.
      It also comes with advanced profiling to dig into state and debug issues.
      However, in many cases the built-in React Context is up to the task and minimises boilerplate.
    </Info>
  )
}

export function Library( props: {bottomSheet?: boolean, theme: Theme, mode: ColorMode}){
  return (
    <Info {...props}>
      Component libraries can be built in Storybook and not only emphasise reusability, but they also provide a consistent set of rules for user interfaces in an organisation.
    </Info>
  )
}

export function Timber( props: {bottomSheet?: boolean, theme: Theme, mode: ColorMode}){
  return (
    <Info {...props}>
      Timber and Twig provide powerful templating that keeps a WordPress theme clean and maintainable. It also allows for much cleaner code reusability using Twig macros and blocks.
    </Info>
  )
}

export function WooCommerce( props: {bottomSheet?: boolean, theme: Theme, mode: ColorMode}){
  return (
    <Info {...props}>
      WooCommerce can be a powerful tool but in many sites too many plugins and oversized images create a slow experience. In ecommerce, speed is everything. I develop on WooCommerce with this in mind and profile the website using Lighthouse to search for ways to improve loading speed.
    </Info>
  )
}

export function Automation( props: {bottomSheet?: boolean, theme: Theme, mode: ColorMode}){
  return (
    <Info {...props}>
      Automate all the things! I love doing this because it saves time and let's you focus on the fun stuff.
      Typically automation will involve an end-to-end build and deployment process, for example, building minified JavaScript and CSS files, and deploying the entire site to a host such as WP Engine or Kinsta. In the case of React apps, look no further than Vercel which takes care of most of this out the box.
    </Info>
  )
}

export function AWS( props: {bottomSheet?: boolean, theme: Theme, mode: ColorMode}){
  return (
    <Info {...props}>
      Over the years I've dealt with plenty of configuration on AWS.
      Though I'd prefer to hand this over to a specialist who can whip up magical terraform scripts,
      I know my way around and can jump in and help whenever necessary.
    </Info>
  )
}

export function Hire( props: {bottomSheet?: boolean, theme: Theme, mode: ColorMode}){
  return (
    <Info {...props}>
      I can work on small-medium sized projects, and I'm available during in most timezones in the EU, as well as UK. Let's chat.
    </Info>
  )
}
