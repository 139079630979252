import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"

const H1 = styled.h1`
  font-size: 2rem;
  padding-bottom: .25rem;
  margin-bottom: .5rem;
  color: ${ p => p.theme.colors.current };
  display: inline-block;
  letter-spacing: 10px;
  border-bottom: 2px solid ${ p => p.theme.colors.green[200] };
`

export function Logo(){
  return (
    <H1>Luke Siedle</H1>
  )
}
