import React, { useEffect, useRef, useState } from "react"
import { Box, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger } from "@chakra-ui/react"

export function Tooltip( {children, Tip}: {children: React.ReactNode, Tip: React.FC<any>}){

  return (
    <Popover trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <Box p={2} px={4}>
          <Tip />
        </Box>
      </PopoverContent>
    </Popover>
  )
}
